import { Button, Spin } from "antd";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as PinIcon } from "../../assets/images/icons/pin.svg";
import AutoComplete from "./AutoComplete";

export default function Map({
  onPinLocation,
  pinCoordinates,
  disabled,
  isLoading = false,
}) {
  const [coordinates, setCoordinates] = useState(pinCoordinates);
  const [initialCoordinates, setInitialCoordinates] = useState(null);
  const [center, setCenter] = useState(pinCoordinates);
  const [auxLoading, setAuxLoading] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({});
  const [mapInstance, setMapInstance] = useState(null);
  const [mapApi, setMapApi] = useState(null);

  const getCurrentPosition = () => {
    setAuxLoading(true);
    try {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(({ coords }) => {
            console.log("primer locations", coords);
            const { latitude: lat, longitude: lng } = coords;
            setCurrentPosition({ lat, lng });
            setAuxLoading(false);
          });
        }
      }).catch((error) => {
        console.error("Error: ", error);
        setAuxLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const apiHasLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
  };

  const addPlace = (place) => {
    setCoordinates({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (coordinates && coordinates.lat) {
      setInitialCoordinates({
        ...coordinates,
      });
    } else {
      try {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          console.log("segundo location", coords);
          const { latitude, longitude } = coords;
          setInitialCoordinates({ lat: latitude, lng: longitude });
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    getCurrentPosition();
  }, []);

  const onMapClick = async ({ lat, lng }) => {
    if (disabled) {
      return;
    }
    onPinLocation({ lat, lng });
    setCoordinates({ lat, lng });
    setCenter({ lat, lng });
  };

  return initialCoordinates && !isLoading ? (
    <>
    <AutoComplete 
      map={mapInstance} 
      mapApi={mapApi} 
      addplace={addPlace} 
      location={coordinates}
      disabled={disabled}
    />
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyCfkxFj0YHsve_5ztLxA167Vjm3R86lZtI",
          libraries: ['places', 'geometry'],
        }}
        defaultCenter={initialCoordinates}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={15}
        onDragEnd={(e) =>
          setCenter({ lat: e.center.lat(), lng: e.center.lng() })
        }
        center={center}
        onClick={({ lat, lng }) => {
          onMapClick({ lat, lng });
        }}
        style={{
          position: "relative",
          top: 0,
          left: 0,
          height: 500,
          width: "100%",
          zIndex: 600,
        }}
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        {coordinates && <PinIcon lat={coordinates.lat} lng={coordinates.lng} />}
      </GoogleMapReact>
      {!disabled && (
        <div
          style={{
            position: "absolute",
            zIndex: 700,
            top: 20,
            left: 20,
          }}
          onClick={(e) => {
            onMapClick(currentPosition);
          }}
        >
          <Button loading={auxLoading} disabled={auxLoading}>
            Ubicacion actual
          </Button>
        </div>
      )}
    </div>
    </>
  ) : (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>
  );
}
